.slick-hero {
    .slick-dots {
        width: 47%;
        @media #{$large-up} {width: 49%;}
        text-align: right;
    }
    .slick-list {
        &:after {
            @include before-block();
            @include skew(-20deg,0deg);
            background-color: #FFF;
            width: 49%;
            height:  45px;
            bottom:  0px;
            left:initial;
            top:initial;
            right:rem-calc(-15);
        }
        .slick-track {
            .slick-slide {
                padding: rem-calc(100 60 40); // Small
                @media #{$medium-up} {padding: rem-calc(300 50 40);} // Medium
                @media #{$large-up} {padding: rem-calc(350 30 250);} // Large+

                height: auto;
                color:#FFF;
                @include black-gradient($height:50%);
                h1 {
                    color:#FFF;
                    line-height:1.2em;
                    @media #{$small-up} {font-size: rem-calc(36);}
                    @media #{$medium-up} {font-size: rem-calc(62);}
                    @media #{$large-up} {font-size: rem-calc(82);}
                }
                p {
                    font-size:rem-calc(24);
                    @media #{$small-up} {font-size:$paragraph-font-size*0.8;}
                    @media #{$medium-up} {font-size:$paragraph-font-size*0.9;}
                    @media #{$large-up} {font-size:$paragraph-font-size;}
                }
                .smgrey {
                    position:absolute;
                    bottom: rem-calc(40);
                    right: rem-calc(20);
                }
                * {
                    z-index:9;
                    position:relative;
                }
                img {
                    z-index:-1;
                }
                &.bgslide {
                    @media #{$small-up} {height: rem-calc(450) !important;}
                    @media #{$medium-up} {height: rem-calc(700) !important;}
                    @media #{$large-up} {height: initial !important;}
                    &.report-slide {
                        padding: rem-calc(350 30 80);
                        @include black-gradient($height:60%, $toColor:rgba(0,0,0,0.9));
                        h1 {
                            font-size: rem-calc(62);
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
