// .bg2 {
//     .slick-slide {
//         .slick-slant {
//             .slide-contents {
//                 position: absolute;
//                 top: 50%;
//                 -webkit-transform: translateY(-50%);
//                 -moz-transform: translateY(-50%);
//                 -ms-transform: translateY(-50%);
//                 transform: translateY(-50%);
//             }
//         }
//     }
// }
/* Slider Structures */
.bg {
    .slick-slant,
    .black-overlay {
        @media #{$small-only} {
            height: auto !important;
        width:100% !important;
        position: relative;
        }
        @media #{$medium-only} {
            height: auto !important;
        width:100% !important;
        position: relative;
        }
        @media #{$large-only} {
            height: auto !important;
        width:100% !important;
        position: relative;
        }
    }
}
.bg2 {
    .slick-slant {
        height: rem-calc(150) !important;
        position: relative;
    }
}
.slick-slider {
    .slick-next {
        right: 20px;
        &:before {background-image: url("/images/arrow-r.png");}
    }
    .slick-prev {
        left: 20px;
        &:before {background-image: url("/images/arrow-l.png");}
    }
    .slick-prev, .slick-next {
        height:50px;
        width:50px;
        opacity: 0.8;
        &:before {
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 100%;
            width: 100%;
            display: block;
            opacity: 0.7;
        }
    }
    .slick-dots {
        bottom: -8px;
        li {
            @media #{$small-only} {margin:0 2px;}
            button:before {
                opacity:1;
                color: #FFF;
                font-size:56px;
                @media #{$small-only} {font-size: 38px;}
            }
        }
        li.slick-active {
            button:before {
                color: $primary-color;
            }
        }
    }



    /* Slanted Slides */
    .slick-slide {
        overflow: hidden;
        .slick-slant {
            float: none;
            width: auto;

            width:100%;
            @media #{$small-only} {
                height: auto !important;
                width:100% !important;
                * {
                    width: 100% !important;
                }
                .black-overlay {
                    height: auto !important;
                }
            }
            @media #{$medium-only} {
                height: auto !important;
                width:100% !important;
                * {
                    width: 100% !important;
                }
                .black-overlay {
                    height: auto !important;
                }
            }
            @media #{$large-only} {
                
                .black-overlay {
                    height: auto !important;
                }
            }

            @media #{$xlarge-up} {
                float:left;
                width: 50%;
                // width:55.5%;
                // @include transform(skew(-15deg,0deg));
            }

            &:nth-of-type(odd) {
                transform-origin: top left; 
                z-index:9;
            }

            &:nth-of-type(even) {
                transform-origin: bottom right;
                -webkit-transform-origin: bottom right;
                -ms-transform-origin: bottom right;
                -moz-transform-origin: bottom right;
                -o-transform-origin: bottom right;
            }

            &:not(.bgslide) {


                &:nth-of-type(odd) {
                    @media #{$small-only} {
                        margin-right: 0; 
                        display: block !important;
                    }
                }
                .white-overlay, .black-overlay {
                    position:relative;
                }
                .slide-contents {
                    padding: rem-calc(10);
                    padding: 0 30px;
                    width: 100%;
                    @media #{$large-up} {
                        padding: rem-calc(10);
                    }
                }
            }

            .slide-contents {
                margin: 0 auto !important;

                img {
                    @include transform(translate(-50%, -50%));
                    position:absolute !important;
                    top:50% !important;
                    left:50% !important;
                    width: auto !important;
                    max-width:120% !important;
                    height: auto !important;
                }
            }
        }
    }

    .slick-list {
        .slick-track {
            .slick-slide {
                display: block !important;
                position:relative;
                width: 100%;

                .bgslide {
                    img {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}
.bg {
    .slick-slide {
        img {
            width: auto !important;
            position: relative !important;
        }
    }
}
/* Full background slide w/ set height */
.bgslide {
    @media #{$small-only} {
        height: auto;
        .black-overlay, 
        .white-overlay {
            position:relative !important;
        }
    }
    @media #{$large-up} {
        height: rem-calc(500);
    }
    background-size: cover;
    background-position: center;
    background-repeat:no-repeat;
    display: table !important;
    h3 {
        @media #{$small-up} {font-size:  rem-calc(22); line-height:1.2em;}
        @media #{$medium-up} {font-size: rem-calc(30);}
        @media #{$large-up} {font-size:  rem-calc(36);}
    }
    p {
        @media #{$small-up} {font-size:$paragraph-font-size*0.6;}
        @media #{$medium-up} {font-size:$paragraph-font-size*0.8;}
        @media #{$large-up} {font-size:$paragraph-font-size*0.9;}
    }

    .full-video {
        padding:0;
        .slide-contents {
            height:100%;
            width:101%;

            @media #{$large-up} {
                width:101%;
                left: 42.3%;
            }

            .flex-video {
                @media #{$large-up} {
                    @include abcenter();
                    height:100%;
                    width:100%;
                    padding-bottom:45%;
                }
                margin-bottom:0;
            }
        }
    }
}

/* White overlay slides */
.white-overlay, .black-overlay {
    position: relative;
    z-index: 2;
    @media #{$xlarge-up} {
        position: absolute;
        height: 100%;
        width: 100%;
    }
    .slide-contents {
        @media #{$xlarge-up} {
            position: absolute;
            @include transform(translate(0%,-50%));
            left: 0;
            top: 50%;
        }
        
    }
}
.white-overlay {
    background-color: rgba(255, 255, 255, 0.75);
    position: relative;
    z-index: 0;
}
.black-overlay {
    background-color: rgba(0,0,0, 0.75);
    position: relative;
    z-index: 1;
    @media #{$small-only} {
        height: auto !important;
    }
    @media #{$medium-only} {
        height: auto !important;
    }
    @media #{$xlarge-up}  {
         &:before {
            content: '';
            width: rem-calc(300);
            background-color: #4e6c28;
            position: absolute;
            top: 0;
            bottom: 0;
            right: rem-calc(-70);
            transform: skew(-15deg);
            z-index: -1;
        }
    }
   
    h1,h2,h3,h4,h5 {
        color: lighten($primary-color, 15%);
    }
    p {
        color: #FFF;
    }
}
.bg2 {
    .slide-contents {
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
        * {
            position: relative;
            z-index: 2;
        }
    }
}
@media #{$xlarge-up} {
    .bg2 {
        .slick-slant:first-of-type {
            &:before {
                content: '';
                width: rem-calc(300);
                background: #546b35;
                position: absolute;
                top: 0;
                bottom: 0;
                right: rem-calc(-54);
                transform: skew(-15deg);
                z-index: 1;
            }
            .slide-contents {
                position: absolute;
                top: 50%;
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                transform: translateY(-50%);
                z-index: 1;
                * {
                    position: relative;
                    z-index: 2;
                }
            }
        }
        .slick-slant {
            position: relative;
            .row {
                padding-top: rem-calc(15);
            padding-bottom: rem-calc(15);
            }
            &:last-of-type {
                position: relative;
                z-index: 0;
            }
        }
    }
}
#chart-area {
    width: 100% !important;
    max-width: 300px;
    height: auto !important;
}

.slick-slant {
    @media #{$small-only} {
        height: auto !important;
    }
    @media #{$medium-only} {
        height: auto !important;
    }
}

