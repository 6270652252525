// Foundation by ZURB
// foundation.zurb.com
// Licensed under MIT Open Source

@import "global";

//
// @variables
//
$include-html-keystroke-classes: $include-html-classes !default;

// We use these to control text styles.
$keystroke-font: "Consolas", "Menlo", "Courier", monospace !default;
$keystroke-font-size: inherit !default;
$keystroke-font-color: $jet !default;
$keystroke-font-color-alt: $white !default;
$keystroke-function-factor: -7% !default;

// We use this to control keystroke padding.
$keystroke-padding: rem-calc(2 4 0) !default;

// We use these to control background and border styles.
$keystroke-bg: scale-color($white, $lightness: $keystroke-function-factor) !default;
$keystroke-border-style: solid !default;
$keystroke-border-width: 1px !default;
$keystroke-border-color: scale-color($keystroke-bg, $lightness: $keystroke-function-factor) !default;
$keystroke-radius: $global-radius !default;

//
// @mixins
//
// We use this mixin to create keystroke styles.
// $bg - Default: $keystroke-bg || scale-color($white, $lightness: $keystroke-function-factor) !default;
@mixin keystroke($bg:$keystroke-bg) {
  // This find the lightness percentage of the background color.
  $bg-lightness: lightness($bg);

  background-color: $bg;
  border-color: scale-color($bg, $lightness: $keystroke-function-factor);

  // We adjust the font color based on the brightness of the background.
  @if $bg-lightness > 70% { color: $keystroke-font-color; }
  @else { color: $keystroke-font-color-alt; }

  border-style: $keystroke-border-style;
  border-width: $keystroke-border-width;
  margin: 0;
  font-family: $keystroke-font;
  font-size: $keystroke-font-size;
  padding: $keystroke-padding;
}

@include exports("keystroke") {
  @if $include-html-keystroke-classes  {
    .keystroke,
    kbd {
      @include keystroke;
      @include radius($keystroke-radius);
    }
  }
}
