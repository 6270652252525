ul.tert-nav {
    list-style: none;
    display:block;

    &:before,
    &:after {content: " "; display: table;}
    &:after {clear: both;}
    clear:both;

    li {
        a {
            color: $primary-color;
            display:block;
            font-size: $paragraph-font-size*0.85;
            line-height:1.2em;
            margin-bottom:0.6em;

            -webkit-transition: all .2s ease-in-out;
            -moz-transition:    all .2s ease-in-out;
            -o-transition:      all .2s ease-in-out;
            transition:         all .2s ease-in-out;

            border-bottom: solid 2px transparent;
            &:hover {
                border-bottom: solid 2px $primary-color;
            }
        }

        @media #{$medium-only} {
            a {
                float:left;
                width:49%;
                margin-right:2%;
            }
            &:nth-of-type(2n) a {margin-right:0;}
        }

        @media #{$large-up} {
            a {
                float:left;
                width:30%;
                margin-right:5%;
            }
            &:nth-of-type(3n) a {margin-right:0;}
        }
        &.active {

        }
    }
    &:after {
        display: block;
        content: "";
        width:140px;
        height:1px;
        clear:both;
        position:relative;
        top:rem-calc(40);
        margin: rem-calc(30) auto rem-calc(65);
        background-color: $charcoal;
    }
}
