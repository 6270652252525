

dl.sub-nav {
    overflow: hidden;
    padding-top:0;
    position:relative;
    dd {
        float: none;
        display: inline-block;
        text-transform: uppercase;
        position:relative;
        overflow: hidden;
        font-size: $paragraph-font-size;
        font-weight: 300;
        margin: rem-calc(0 -10);

        @include transition(0.3s);
        @media #{$small-only} {
            float: none;
            width:90%;
            margin:0 0 0 0 !important;
            font-size: $paragraph-font-size*0.75;
            line-height:1.2em;
        }
        
        &:before {
            @include transition(0.3s);
            @include skew(-20deg,0deg);
            @include before-block();

            z-index:-1;
            background-color: #EEEEEE;
            left: 6%;
            width:88%;

            @media #{$small-only} {
                @include skew(0deg,0deg);
                width: 100%;
                left:0 !important;
            }
        }
        
        &:hover {
            &:before {background-color: $primary-color;}
            a {color: #FFF;}
        }

        a {
            background: transparent;
            color: $primary-color;
            display:block;
            z-index:9;
            overflow:visible;
            padding: rem-calc(15 50);
            @media #{$small-only} {
                padding: rem-calc(15);
                width:100%;
                margin:0;
            }
        }

        &:first-of-type {
            &:before {
                left: -6%;
                width:98%;
            }
            a {
                padding: rem-calc(15 50 15 35);
            }
        }

        &:last-of-type {
            &:before {
                left: 7%;
                width: 100%;
            }
            a {
                padding: rem-calc(15 35 15 50);
            }
        }

        // Active Button Styling        
        &.active {
            &:before {
                background: $white-smoke;
            }
            a {
                font-weight: 300;
                background: transparent;
                color: $base;
                &:hover {
                    background: transparent;
                    color: $base;
                }
            }
            &:hover {
                &:before {
                    background: $smoke;
                }
            }
        }
    }
}