/* Mixins
/*********************/

@mixin clip-path4($a, $b, $c, $d) {
    -webkit-clip-path: polygon($a, $b, $c, $d);
    -moz-clip-path:    polygon($a, $b, $c, $d);
    -ms-clip-path:     polygon($a, $b, $c, $d);
    clip-path:         polygon($a, $b, $c, $d);
}

@mixin clip-path5($a, $b, $c, $d, $e) {
    -webkit-clip-path: polygon($a, $b, $c, $d, $e);
    -moz-clip-path:    polygon($a, $b, $c, $d, $e);
    -ms-clip-path:     polygon($a, $b, $c, $d, $e);
    clip-path:         polygon($a, $b, $c, $d, $e);
}

@mixin abcenter() {
    position: absolute;
    left: 50%;
    top: 50%;
    @include transform(translate(-50%,-50%));
}

@mixin before-block {
    display:block;
    content: "";
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
}

@mixin box-shadow($vars...) {
    -webkit-box-shadow: $vars;
    -moz-box-shadow:    $vars;
    box-shadow:         $vars;
}

@mixin not-large-autoheight() {
    @media #{$small-only} {height: auto !important}
    @media #{$medium-only} {height: auto !important}
}


/* Globals
/*********************/

a {@include transition(0.3s);}

/* Positioning
/*********************/

.abcenter {@include abcenter();}

.no-padding-top {padding: rem-calc(0 0 15) !important;}
.no-padding {padding: 0 !important;}

.no-margin-top {margin: rem-calc(0 0 15) !important;}
.no-margin {margin: 0 !important;}



/* Backgrounds
/*********************/

.bg-grey {
    background-color: $iron;
    padding:rem-calc(20 0 50);
}

.bg-oil {
    background-color: $oil;
    & * {color: #FFF;}
}

.bg-green {
    background-color: $light-green !important;
    padding:rem-calc(20 0 50);
}

.bg-primary-color {
    background-color: $primary-color !important;
    & * {color: #FFF !important;}
}

.inline {
    display: inline-block;
    .top {vertical-align: top;}
}

.bgFull {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.bg-swimmer {
    background-image: url('../../images/water-hang.jpg');
}



/* Rows
/*********************/

.row {
    padding: rem-calc(15 0);
}

#content > .row:first-child {
    padding-top:0;
}

.fullWidth {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    overflow: hidden;
}

.clearit {
    width: 100% !important;
    float: none !important;
    clear: both !important;
}


/* Buttons
/*********************/

/* Absolutely centers a button within its parent */
button.abcenter, .button.abcenter {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -27px;
    margin-left: -100px;
}

/* Absolutely positions a button in the upper center */
button.abcenter-top, .button.abcenter-top {
    position: absolute;
    top: 20%;
    left: 50%;
    margin-top: -27px;
    margin-left: -100px;
}



/* Containers
/*********************/

.white-box {
    background-color: rgba(255,255,255,0.85);
    padding: rem-calc(40);
    margin-bottom: rem-calc(30);
    h3 {
        font-size: rem-calc(24);
    }
}






