form {
    font-size: $paragraph-font-size;
    text-transform: uppercase;
    font-weight: 100;
    color: $monsoon;
    margin-bottom:rem-calc(35);

    &:before, &:after {
         content: " ";
         display: table;
     }
     &:after {clear: both;}

    input {
        text-transform: uppercase;
        font-size: $paragraph-font-size !important;
        font-weight: 100;
    }

    & > div {
        float: none;
        position:relative;
        width: 100%;
        @media #{$medium-up} {float:left;}
    }

    &.form-floats > div {
        margin: rem-calc(0 0 8 0);
        
        @media #{$medium-up} {
            width:48%;
            &:nth-child(odd) {
                margin-right:4%;
            }
        }
    }

    @media #{$small-only} {
        label {display: none;}
    }

    
    input, textarea {
        text-transform: uppercase;
        font-size: $paragraph-font-size !important;
        font-weight: 100;
        position:relative;
        z-index:1;

        @media #{$medium-up} {
            &:valid {background:#FFF} // Hides label
            &:focus {}
            &:focus + label {
                bottom: 100%;
                margin-top: -21px;
                &:after {
                    display: block;
                    content: "";
                    background-color: #567335;
                    width: 110%;
                    height: 100%;
                    position: absolute;
                    left: 4px;
                    top: 0;
                    z-index: -1;
                    @include skew(-20deg,0deg);
                }
            }
        }
    }

    label {
        @include transition(0.3s);
        position: absolute;
        text-transform: none;
        color: #FFF;
        height:40%;
        padding: 1px 10px 1px 15px;
        top: 0;
        bottom: 0;
        left: 0;
        z-index:0;
        background: #FFF;
        font-size: 70%;
    }

    textarea {
      display: block;
      resize: vertical;
    }

    input[type="radio"] {
        height: rem-calc(20);
        width:  rem-calc(20);
    }
}
