h1, 
h2 {
    text-transform: uppercase;
}

h2 {
    @media #{$small-only} {
        font-size:rem-calc(28);
    }
}
h3 {
    color: $primary-color;
    @media #{$small-only} {
        font-size:rem-calc(22);
    }
}
h4 {
    font-size:rem-calc(24);
}
p {
    font-weight:300;
    font-size: rem-calc(22);
    @media #{$small-only} {
        font-size:rem-calc(16);
    }
}
h2 {
    font-weight:400;
    font-size:rem-calc(24);
    line-height: 1.2em;
    @media #{$medium-up} {
        font-size: rem-calc(32);
        line-height: 1.3em;
    }
    @media #{$large-up} {
        font-size: rem-calc(55);
    }
}

h3 {
    font-weight:500;
    font-size:rem-calc(30);
    text-transform: uppercase;
    color: #000;
}
strong {
    font-weight:600;
}

.-caps {
    text-transform: uppercase;
}


.smgrey {
    font-size: $paragraph-font-size*0.7 !important;
    color: #CCC;
}

.charcoal {
    color: $charcoal;
}

.strong-h4 {
    h4 {
        color: $primary-color;
        font-weight: 400;
    }
}

.upper-grey-h4 {
    h4 {
        color: $monsoon;
        font-weight: 600;
        text-transform: uppercase;
        &:after {
            display: block;
            content: "";
            width:80px;
            height:1px;
            margin: rem-calc(30) auto;
            background-color: $charcoal;
        }
    }
}


.h4-dividers {
    h4 {
        &:before {
            display: block;
            content: "";
            width:80px;
            height:1px;
            margin: rem-calc(30) auto;
            background-color: $charcoal;
        }
        &:first-child:before {
            display:none;
        }
    }
}

