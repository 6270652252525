.box-grid {
    padding: rem-calc(30 0);

    @media #{$small-up} {
        h2 {
            font-size: rem-calc(38);
            font-weight:200;
            line-height:1.1em;
            height:1.1em;
        }
    }
    &.bgFull h2 {color: #FFF;}
    @media #{$medium-up} {h2 {font-size: rem-calc(30);}}
    @media #{$large-up} {h2 {font-size: rem-calc(26);}}
    @media #{$xxlarge-up} {h2 {font-size: rem-calc(32);}}

    .medium-6 {
        margin: rem-calc(15) 0;
    }

    .white-box {
        margin-bottom:0;
        @media #{$small-only} {margin-bottom: rem-calc(50);}
        position: relative;
        padding-bottom: rem-calc(80);
        h4 {
            font-size: rem-calc(14);
            text-transform: uppercase;
            font-weight: 400;
            color: $charcoal;
            margin-bottom: 0;
        }
        h3 {margin-bottom: 0.2rem;}
        p {
            font-size: rem-calc(18);
            color: $charcoal;
            margin-bottom:0;
            font-weight:300;
        }
        .button, button {
            position:absolute;
            bottom:0;
            left:50%;
            width:rem-calc(220);
            margin-left:rem-calc(-110);
            margin-bottom:rem-calc(10);
        }
        .newsletter-item {
            @include black-gradient($height:60%);
            display:block;
            position: absolute;
            top:0;
            left:0;
            width:100%;
            height:85%;
            min-height:300px;
            overflow:hidden;
            @include transition(0.3s);
            .newsletter-text {
                position:absolute;
                bottom:3%;
                width:100%;
                @include transition(0.3s);
                h3 {
                    color: lighten($primary-color, 15%);
                    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.6);
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: rem-calc(34);

                }
                h4 {
                    color: #FFF;
                }
                p {
                    color: #FFF;
                    opacity:0;
                    height:0;
                    margin:0 rem-calc(25);

                    position:relative;
                    top: rem-calc(25);

                    font-size: $paragraph-font-size*0.8;
                    @include transition(0.3s);
                    &:before { // Divider Line
                        display:block;
                        content:" ";
                        margin:0 auto rem-calc(20);
                        width:0%;
                        height:1px;
                        background-color:$smoke;
                        @include transition(0.3s);
                    }
                }
            }
            &:hover {
                @include black-gradient($height: 200%, $position: absolute);
                .newsletter-text {
                    bottom:60%;
                }
                h4 {

                }
                p {
                    opacity:1;
                    &:before { // Divider Line Transition
                        width:60%;
                        transition-delay: 0.32s;
                    }
                }
            }
        }
    }
    
}








