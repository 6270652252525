$dirt: #2f2d25;


#footer {
    position: relative;
    background: $dirt;
    text-align: center;
    padding: 0;
    font-size: $paragraph-font-size*0.8;
    .row .row {
        max-width:100% !important;
    }
    * {
        color: $iron;
        line-height: 1.5em;
    }

    h4 {
        font-size: $h4-font-size*0.85;
        font-weight:400;
        margin-bottom: 0;
    }
    p, li {
        font-size: $paragraph-font-size*0.8;
        font-weight:100;
    }
    a {
        color: $primary-color;
        &:hover {color: lighten($primary-color, 25%);}
        font-weight:400;
    }
    ul {
        list-style: none;
        margin:0;
        padding:0;
        li {
            margin:0;
            padding:0;
        }
    }

    & > .row:first-of-type {
        padding:0;
        & > .columns:first-of-type {
            padding: rem-calc(50 0 70);
            /*&:before {
                @media #{$large-up} {
                    @include before-block();
                    @include transform(translate(100%,0) skew(-15deg,0deg));
                    background-color: $dirt;
                    width:30%;
                    height:  46px;
                    top:    -45px;
                    left:initial;
                    right: 51.6%;
                }
            }*/
        }
        /*&:before {
            @media #{$large-up} {
                @include before-block();
                background-color: $dirt;
                width: 40%;
                height:  45px;
                top:    -45px;
                left:initial;
                right: 0;
            }
        }*/
    }

    
}


