.fb-like-box, .fb-like-box span, .fb-like-box.fb_iframe_widget span iframe,
.twitter-timeline, .twitter-timeline-rendered {
    width: 100% !important;
    height: 100% !important;
    position:absolute !important;
    top:0 !important;
    left:0 !important;
}



.snapwidget-widget {
    width: 96% !important;
    height: 96% !important;
    position:absolute !important;
    top:2% !important;
    left:2% !important;
}
